import './App.css';
import { Button, Flex, View } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { fetchAuthSession } from 'aws-amplify/auth';
import axios from "axios";
import { useState } from "react";
import logo from './TeleDaas_Colour.png';

export default function LaunchSimplicity( { signOut, customState } )
{
   const streamUrl = process.env.REACT_APP_STREAM_URL;
   const [ errorText, setErrorText ] = useState( "" );
   const [ loading, setLoading ] = useState( false );
   const [ tokenSession ] = useState( customState );

   function getSimplicityLink()
   {
      setLoading( true )

      const [ token, sessionType ] = [ tokenSession.dataToken, tokenSession.sessionType ]

      if ( !token || !sessionType )
      {
         setErrorText( "Token or session type are missing. Please retry access PDF link." );
         setLoading( false );
         return;
      }
      fetchAuthSession()
         .then( session =>
            axios.get( streamUrl + '?' + 'token=' + token + '&sessionType=' + sessionType,
               { headers: { 'Authorization': `${ session.tokens.idToken.toString() }` } }
            ) )
         .then( ( response ) =>
         {
            setLoading( false )
            setErrorText( "" )
            if ( response.status === 200 && response.data )
            {
               window.location.assign( response.data )
            }
         } )
         .catch( error =>
         {
            setLoading( false )
            console.log( "Error retrieving the streaming link " + error )
            console.log( error.response?.data )
            setErrorText( "Unable to retrieve a streaming link, please try again" )
         } );
   }

   return (
      <>
         <Flex id="content" direction="column" alignContent="center">
            <View id="signOutButton">
               <Button onClick={ signOut }>Sign Out</Button>
            </View>
            <View width="100%" justifyContent="center">
               <img src={ logo } width="800" alt="Simplicit90Y, delivered by Mirada TeleDaaS"/>
            </View>
            <View width="100%">
               <Button
                  id="launchButton"
                  variation="primary"
                  size="large"
                  isFullWidth={ true }
                  loadingText="Loading"
                  onClick={ getSimplicityLink }
                  isLoading={ loading }
               >
                  <span>Launch Simplicit<span style={ { verticalAlign: 'super', fontSize: 'smaller' } }>90</span>Y</span>
               </Button>
            </View>
            <View id="errorMessage" width="100%">
               { errorText }
            </View>
         </Flex>
      </>
   )
}