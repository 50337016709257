import { signInWithRedirect, getCurrentUser, signOut, AuthError } from "aws-amplify/auth";
import { useEffect, useState } from "react";
import { Hub } from "aws-amplify/utils";
import 'aws-amplify/auth/enable-oauth-listener';
import { Flex } from "@aws-amplify/ui-react";

function SignOut( { setSignedOut, setAuthError } )
{
   setSignedOut( true );
   signOut().catch( () => setAuthError( "Error occurred attempting to sign-out. Please contact an administrator." ) );
   return ( <></> );
}

export function OAuthAuthenticator( { children, passedState } )
{
   const loadingState = "loading";
   const signedInState = "signedIn";
   const signInWithRedirectState = "signInWithRedirect";
   const signedOutState = "signedOut";
   const authErrorState = "authError";
   const separation = "_miradatokensessionsplit_"
   const [ authState, setAuthState ] = useState( loadingState );
   const [ sessionToken, setSessionToken ] = useState( passedState );
   const [ authError, setAuthError ] = useState( null );
   const [ signedOut, setSignedOut ] = useState( false );

   useEffect( () =>
   {
      testAuthState();
      if ( authState === loadingState && !signedOut )
      {
         const options = { customState : [ sessionToken.dataToken, sessionToken.sessionType ].join( separation ) }
         signInWithRedirect( options ).catch( err => {
            if ( err instanceof AuthError && err.name === 'UserAlreadyAuthenticatedException' )
            {
               console.log( err );
            }
            else
            {
               console.error( 'Sign in redirect failed: ', err );
               setAuthError( "Unable to redirect to sign-in, error in deployed configuration. Please contact an administrator." );
               setAuthState( authErrorState );
            }
         } );
      }
   }, [ signedOut ] )

   useEffect( () =>
   {
      const unsubscribe = Hub.listen( 'auth', ( { payload: { event, data } } ) =>
         {
            switch ( event )
            {
               case signInWithRedirectState:
                  setAuthState( signInWithRedirectState );
                  break;

               case signedInState:
                  setAuthState( signedInState );
                  break;

               case signedOutState:
                  setAuthState( signedOutState );
                  break;

               case 'signInWithRedirect_failure':
                  setAuthError( "Unable to redirect to sign-in, error with provider. Please contact an administrator." )
                  setAuthState( authErrorState );
                  break;

               case 'customOAuthState':
                  if ( ( typeof data === 'string' || data instanceof String ) && data.includes( separation ) )
                  {
                     const [ token, sessionType ] = data.split( separation );
                     setSessionToken( { dataToken : token, sessionType : sessionType } );
                  }
                  else
                  {
                     setAuthError( 'Sign in failed as no token or session type retrieved from response' )
                     setAuthState( authErrorState );
                  }
                  break;
            }
         }
      )
      testAuthState();
      return unsubscribe;
   }, [] );

   const testAuthState = () =>
   {
      // if we've signed out don't attempt to sign-in again
      if ( authState === signedOutState )
      {
         return;
      }
      getCurrentUser().then( _ => setAuthState( signedInState ) )
                      .catch( _ => setAuthState( loadingState ) )
   };

   function handleSignOut()
   {
      setAuthState( signedOutState );
   }

   return (
      <>
         {
            authState === loadingState &&
            <Flex>Redirecting to single sign-on provider</Flex>
         }
         {
            authState === authErrorState && // TODO DAAS-405: make this error a part of a separate page.
            <Flex >{ String( authError ) }</Flex>
         }
         {
            authState === signedOutState &&
            <SignOut setSignedOut={ setSignedOut } setAuthError={ setAuthError }/>
         }
         {
            authState === signInWithRedirectState &&
            <Flex>Redirecting to single sign-on provider</Flex>
         }
         {
            authState === signedInState &&
            children( () => handleSignOut(), sessionToken )
         }
      </>
   )
}